import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, BoxBody, BoxHeader, Container, Text, FlexBox, Button } from '@traveloka/soya-components';

interface Props {
  title: string;
  guidelineLink?: string;
  rightComponent?: ReactNode;
}

const PageLayout = (props: PropsWithChildren<Props>) => {
  const { title, guidelineLink, rightComponent, children } = props;

  const renderGuideline = () => {
    if (!guidelineLink) {
      return null;
    }

    const handleOpenGuideline = () => window.open(guidelineLink, '_blank');

    return (
      <>
        <Text size={14}>{'Please read the '}</Text>
        <Button onClick={handleOpenGuideline} iconProps={{ icon: 'subject' }} variant="gray" size="xs">
          {'Guideline'}
        </Button>
        <Text size={14}>{' before using it.'}</Text>
      </>
    );
  };

  return (
    <Container width="100%" padding={false}>
      <Box>
        <BoxHeader>
          <Text size={24}>{title}</Text>
        </BoxHeader>
        <BoxBody>
          <FlexBox halign="between">
            <FlexBox valign="baseline">
              <Text size={14}>
                {`Welcome to ${title} tool 👋 `}
                {renderGuideline()}
              </Text>
            </FlexBox>
            {rightComponent}
          </FlexBox>
        </BoxBody>
      </Box>
      {children}
    </Container>
  );
};

export default PageLayout;
