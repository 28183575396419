import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useLocalStorage } from 'react-use';
import xor from 'lodash/xor';

import { createPageWithOIDCAuth } from '@traveloka/soya-auth';

import { FlexBox, Text, Collapse, CollapsePanel, Button, ButtonGroup, Icon, Badge } from '@traveloka/soya-components';

import { useMenuList, MenuData } from 'common/components/Layout/Layout';
import PageLayout from 'common/components/PageLayout';

const SearchBar = dynamic(() => import('./components/SearchBar'));

const LandingContent = () => {
  const menuList = useMenuList();
  const { query, push } = useRouter();
  const searchQuery = query.q as string;

  const [favoriteList, setFavoriteList] = useLocalStorage<string[]>('landing:favorite-menu-list', [], {
    raw: false,
    serializer: list => list.join('|'),
    deserializer: value => value.split('|'),
  });

  const subMenuList = menuList.flatMap(menu => menu.subMenuList).filter(subMenu => favoriteList.includes(subMenu.path));

  const modifiedMenuList: MenuData[] = [
    {
      title: 'Favorites',
      iconText: <Icon icon="local-offer" />,
      subMenuList,
    },
    ...menuList.slice(1),
  ];

  const filteredMenuList = modifiedMenuList.map(menu => {
    if (!searchQuery) {
      return menu;
    }
    const filteredSubMenuList = menu.subMenuList.filter(subMenu => {
      const title = subMenu.title.toLocaleLowerCase();
      return title.includes(searchQuery.toLocaleLowerCase());
    });
    return {
      ...menu,
      subMenuList: filteredSubMenuList,
    };
  });

  return (
    <PageLayout title="Experience Operation Dashboard (EXPOD)" rightComponent={<SearchBar />}>
      <Collapse key={searchQuery} activeIndex={searchQuery ? undefined : 0} marginTop>
        {filteredMenuList.map(menu => {
          const { title, iconText, subMenuList } = menu;
          const CollapseHeader = ({ toggle }: { toggle: Function }) => (
            <FlexBox
              style={{ cursor: 'pointer' }}
              paddingLeft
              paddingRight
              paddingTop={14}
              paddingBottom={14}
              onClick={toggle}
            >
              {iconText}
              <Text marginLeft weight="medium">
                {title}
                <Badge color="gray-light" marginLeft="sm" isPill>
                  {subMenuList.length}
                </Badge>
              </Text>
            </FlexBox>
          );

          const renderContent = () => {
            if (!subMenuList.length) {
              return (
                <FlexBox halign="center">
                  <Text color="muted">{`You don't have any ${title} tools`}</Text>
                </FlexBox>
              );
            }

            return (
              <>
                {subMenuList.map(({ title, path }) => {
                  const handleFavoriteClick = () => {
                    setFavoriteList(list => xor(list, [path]));
                  };
                  const handleOpen = () => push(path);

                  const isFavorite = favoriteList.includes(path);
                  const color = isFavorite ? 'crimson' : 'gray';

                  return (
                    <ButtonGroup size="sm" marginBottom marginRight key={title}>
                      <Button onClick={handleFavoriteClick} iconProps={{ icon: 'local-offer', color }} />
                      <Button onClick={handleOpen}>{title}</Button>
                    </ButtonGroup>
                  );
                })}
              </>
            );
          };

          return (
            <CollapsePanel isOpen={subMenuList.length > 0} key={title} customHeader={CollapseHeader}>
              {renderContent()}
            </CollapsePanel>
          );
        })}
      </Collapse>
    </PageLayout>
  );
};

export default createPageWithOIDCAuth()(LandingContent);
