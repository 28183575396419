import React from 'react';

import { createPageWithOIDCAuth } from '@traveloka/soya-auth';

import Layout from 'common/components/Layout';
import LandingContent from 'modules/landing/LandingContent';

const PageContent = () => {
  return (
    <Layout title="Experience Operation Dashboard">
      <LandingContent />
    </Layout>
  );
};

export default createPageWithOIDCAuth()(PageContent);
